* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #0D0D0D;
  color: white;
}

input,
input[type="search"],
input:focus,
input:active,
input:hover {
  border: none;
  outline: none;
}

.navbarSearch {
  background: linear-gradient(218.07deg, #07D6B8 34.61%, #049BE2 66.54%);
}

.socialIcons {
  border: 3px solid #0ACB83;
  padding: 0.3rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.socialIcons:hover , .socialIcons:active , .socialIcons:focus {
  opacity: 0.9;
}

li {
  list-style: none;
}

.AiImages{
  width: 60px;
}

.genBtns {
  background: linear-gradient(90deg, rgba(36, 104, 154, 0.2) 0%, rgba(36, 104, 154, 0.2) 100%);
}